import React, { forwardRef } from "react"

import ErrorBoundary, { ErrorBoundaryProps } from "./ErrorBoundary"

export function withErrorBoundary<T extends object>(
  Component: React.ComponentType<T>,
  config: Omit<ErrorBoundaryProps, "children">,
) {
  const WrappedComponent = forwardRef((props: T, ref) => (
    <ErrorBoundary {...config}>
      <Component {...props} ref={ref} />
    </ErrorBoundary>
  ))
  WrappedComponent.displayName = `withErrorBoundary(${Component.displayName || Component.name || "Component"})`
  return WrappedComponent
}
