import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  AccountCircle,
  ArchiveOutlined,
  AutoAwesome,
  AutoAwesomeOutlined,
  Bolt,
  Check,
  CheckCircleOutlineRounded,
  Close,
  CommentOutlined,
  CommentsDisabledOutlined,
  ContentCopy,
  DeleteForeverOutlined,
  Diversity3,
  DoNotTouchOutlined,
  Edit,
  ErrorOutlineRounded,
  FileUpload,
  FileUploadOutlined,
  FlashOnOutlined,
  Instagram,
  Link,
  Logout,
  MarkChatRead,
  MergeOutlined,
  PhoneForwardedTwoTone,
  Place,
  PushPin,
  Reddit,
  RocketLaunch,
  Send,
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  Sync,
  SyncDisabled,
  VisibilityOffOutlined,
  VisibilityOutlined,
  VolumeOff,
  VolumeUp,
  X,
  YouTube,
} from "@mui/icons-material"

const muiIcons = {
  AccountCircle,
  ArchiveOutlined,
  AutoAwesome,
  AutoAwesomeOutlined,
  Bolt,
  Check,
  CheckCircleOutlineRounded,
  Close,
  CommentOutlined,
  CommentsDisabledOutlined,
  ContentCopy,
  DeleteForeverOutlined,
  Diversity3,
  DoNotTouchOutlined,
  Edit,
  ErrorOutlineRounded,
  FileUpload,
  FileUploadOutlined,
  FlashOnOutlined,
  Instagram,
  Link,
  Logout,
  MarkChatRead,
  MergeOutlined,
  PhoneForwardedTwoTone,
  Place,
  PushPin,
  Reddit,
  RocketLaunch,
  Send,
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  Sync,
  SyncDisabled,
  VisibilityOffOutlined,
  VisibilityOutlined,
  VolumeOff,
  VolumeUp,
  X,
  YouTube,
}

/**
 * Icon
 *
 * The Icon object wraps icons provided by MUI to add theme-based related
 * configuration through styled-system. The Icon object is not a component
 * itself, and should not be used directly. Rather, MUI icon components should
 * be referenced by name off this object.
 *
 * Example, for MUI's "Close" icon:
 *
 * // Don't do this:
 * <Close/>
 *
 * // Do this:
 * <Icon.Close/>
 *
 * Note:
 * In order to keep bundle size small, we do _not_ import every MUI icon here by
 * default. If you need to use a new MUI icon that is not already in use
 * elsewhere in the app, you should both add it to the import statement at the
 * top of this file, and add it to the muiIcons object defined above.
 *
 * @type {typeof muiIcons}
 */

const Icon = Object.entries(muiIcons).reduce((memo, [name, MUIIcon]) => {
  const IconView = styled(MUIIcon).attrs(props => {
    const { className, color, height, width } = props;

    const sx = { color, height, width };

    return {
      sx: sx,
      xmlns: "http://www.w3.org/2000/svg",
      stroke: "",
      fill: "",
      className: classNames(className, "icon"),
    };
  })``;

  IconView.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  IconView.defaultProps = {
    color: "currentColor",
  };

  memo[name] = IconView;

  return memo;
}, {});

export default Icon;
