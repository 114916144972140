import { useQuery } from "@tanstack/react-query"
import React, { useState } from "react"
import { Link } from "react-router-dom"

import consts from "@guardian/Constants"
import { IncidentService } from "@guardian/Services/Incident"
import type { EnrichedIncident2 } from "@guardian/Types/Incident"
import * as incUtil from "@guardian/Utils/incident"

import { Button } from "@guardian/UI/Button"
import { Icon } from "@guardian/UI/Icon"
import { TextInput } from "@guardian/UI/Legacy/TextInput"
import { Spinner } from "@guardian/UI/Loader"

import styles from "./styles.module.scss"

const toRad = (degrees: number) => degrees * (Math.PI / 180)

const toMiles = (km: number) => (km * 1000) / consts.MileToMeter

const calculateHaversine = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) => {
  const R = 6371 // Earth's radius in kilometers
  const dLat = toRad(lat2 - lat1)
  const dLon = toRad(lon2 - lon1)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c
}

const compareIncidents = (inc1: EnrichedIncident2, inc2: EnrichedIncident2) => {
  const parsed = {
    inc1: {
      id: inc1.id,
      cityCode: inc1.location.cityCode,
      isAutogen: incUtil.isAutoGeneratedIncident(inc1),
      isDeleted: inc1.deleted ?? false,
      createdAt: incUtil.getCreatedAt(inc1),
      firstRev: incUtil.getFirstRev(inc1),
    },
    inc2: {
      id: inc2.id,
      cityCode: inc2.location.cityCode,
      isAutogen: incUtil.isAutoGeneratedIncident(inc2),
      isDeleted: inc2.deleted ?? false,
      createdAt: incUtil.getCreatedAt(inc2),
      firstRev: incUtil.getFirstRev(inc2),
    },
  }

  const timeDiff =
    Math.abs(
      new Date(parsed.inc1.createdAt).getTime() -
        new Date(parsed.inc2.createdAt).getTime(),
    ) / 1000 // in seconds

  const originalDistance = calculateHaversine(
    parsed.inc1.firstRev?.location?.lat ?? 0,
    parsed.inc1.firstRev?.location?.lng ?? 0,
    parsed.inc2.firstRev?.location?.lat ?? 0,
    parsed.inc2.firstRev?.location?.lng ?? 0,
  )

  const distance = calculateHaversine(
    inc1.location?.lat ?? 0,
    inc1.location?.lng ?? 0,
    inc2.location?.lat ?? 0,
    inc2.location?.lng ?? 0,
  )

  return {
    id: `${inc1.id}||${inc2.id}`,
    ...parsed,
    timeDiff,
    originalDistance,
    distance,
  }
}

const formatTime = (time: number) => {
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time % 3600) / 60)
  const seconds = Math.floor(time % 60)

  const paddedTime = (num: number) => String(num).padStart(2, "0")

  return [hours, minutes, seconds].map(paddedTime).join(":")
}

const AutogenDebugger = () => {
  const [incidentIds, setIncidentIds] = useState<string[]>([])

  const {
    data: incidents = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["incidents", incidentIds],
    queryFn: async () => {
      if (incidentIds.length === 0) return []

      return IncidentService.getIncidents({ incidentIds })
    },
    onError: err => {
      // eslint-disable-next-line no-console
      console.error("Error fetching incidents", err)
    },
    enabled: !!incidentIds,
  })

  const comparisons = React.useMemo(
    () =>
      incidents.flatMap((incident, index) =>
        incidents
          .slice(index + 1)
          .map(otherIncident => compareIncidents(incident, otherIncident)),
      ),
    [incidents],
  )

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = new FormData(e.currentTarget)
    const incidentIdsString = data.get("incidentIds")?.toString() ?? ""
    const ids = new Set(
      incidentIdsString
        .split(",")
        .map(id => id.trim())
        .filter(Boolean),
    )
    setIncidentIds(Array.from(ids))
  }

  const isFetching = !!incidentIds && isLoading
  return (
    <div className={styles.container}>
      <form className={styles.inputSection} onSubmit={onSubmit}>
        <TextInput
          className={styles.input}
          label='Incident IDs'
          placeholder='Enter incident IDs separated by commas'
          disabled={isFetching}
          name='incidentIds'
          notched
        />
        <Button type='submit' disabled={isFetching} isPrimary>
          {isFetching ? <Spinner /> : "Check Incidents"}
        </Button>
      </form>

      {!!error && <div className={styles.error}>Error fetching incidents</div>}

      <div className={styles.comparisonsGrid}>
        {comparisons.map((comparison, index) => (
          <div key={comparison.id} className={styles.comparisonCard}>
            <h3>Comparison {index + 1}</h3>
            <div className={styles.incidentDetails}>
              {[comparison.inc1, comparison.inc2].map((incident, incIdx) => (
                <div key={incident.id}>
                  <h4>
                    Incident {incIdx + 1}:{" "}
                    <Link to={`/incidents/${incident.id}`} target='_blank'>
                      {incident.id}
                    </Link>
                  </h4>
                  <dl>
                    <dt>Created</dt>
                    <dd>{new Date(incident.createdAt).toLocaleString()}</dd>
                    <dt>City Code</dt>
                    <dd>{incident.cityCode}</dd>
                    <dt>Is AutoGen</dt>
                    <dd>
                      {incident.isAutogen ? (
                        <Icon.Check color='success' />
                      ) : (
                        <Icon.Close color='error' />
                      )}
                    </dd>
                    <dt>Is Deleted</dt>
                    <dd>
                      {incident.isDeleted ? (
                        <Icon.Check color='success' />
                      ) : (
                        <Icon.Close color='error' />
                      )}
                    </dd>
                  </dl>
                </div>
              ))}
            </div>
            <hr />
            <div className={styles.metrics}>
              <dl>
                <dt>Time Difference</dt>
                <dd>{formatTime(comparison.timeDiff)}</dd>
                <dt>Distance</dt>
                <dd>
                  {comparison.distance.toFixed(2)} km (
                  {toMiles(comparison.distance).toFixed(2)} mi)
                </dd>
                <dt>Original Distance</dt>
                <dd>
                  {comparison.originalDistance.toFixed(2)} km (
                  {toMiles(comparison.originalDistance).toFixed(2)} mi)
                </dd>
              </dl>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AutogenDebugger
