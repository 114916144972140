import { AxiosResponse } from "axios"
import { OptimusAPI } from "@guardian/API/Optimus"
import type { Location } from "@guardian/Types/Common"
import type { Incident2General, NotifExpansionSuggestion } from "@guardian/Types/Incident"

interface IncidentsCreateIncidentContentImageData {
  imageUrl: string
  thumbUrl: string
  isReporter?: boolean
}

interface IncidentsCreateIncidentModulesData {
  modules: {
    type: string
    url: string | undefined
    title: string
    isHero: boolean | undefined
    rank: number
    template: {
      name: string
      gcsUrl: string
    }
    id: string
  }[]
}

interface IncidentsCreateIncidentWebViewData {
  webViewUrl: string
  thumbUrl: string
}

interface IncidentsGetIncidentsParams {
  incidentIds: string[]
  updateLimit: number
  withNibs?: string
  withNotifs?: string
  withStats?: string
}

interface IncidentsGetIncidentChatHistoryParams {
  incidentId: string
  limit: number
  before: string
}

interface IncidentsUpdateIncidentChatHistoryData {
  incidentId: string
  message: string
}

interface IncidentsUpdateIncidentCloseStatusData {
  closed: boolean
}

interface IncidentsUpdateIncidentContentRankData {
  contentType: string
  contentId: string
  rank: number
}

interface IncidentsUpdateIncidentDisplayStyleData {
  displayStyle: string
}

interface IncidentsUpdateIncidentMagicIncidentData {
  magicMomentsTag: string
}

interface IncidentsUpdateIncidentMapVisibilityData {
  mapVisibility: string
}

interface IncidentsUpdateIncidentNibData {
  id: string
  text: string
  autoExpand: boolean
}

interface IncidentsUpdateIncidentRadioDeskActionsData {
  attributes: { [key: string]: boolean }
}

interface IncidentsUpdateIncidentRevisionData {
  id: string
  incidentId?: string
  updateId?: string
  updateSequence?: number
  title?: string
  text?: string
  level?: number
  hazard_level?: number
  confidence_level?: number
  newsLevel?: number
  location?: Location
  occurredAt?: Date
  source?: string
  tagIds?: number[]
  tags?: {
    id: number
    name: string
    display_name: string
    type: string
    user_id?: string
    sensitive?: boolean
  }[]
  clipIds?: string[]
  iglStreamId?: string
  prompt911?: boolean
  content?: {
    contentType: string
    contentId: string
  }
  psnId?: string
  clearanceLevel?: number
  dontNotify?: boolean
  excludeFromPath?: boolean
  manualCategorySet?: boolean
  citywide?: {
    incidentID?: string
    geographyType?: string
    geographyCode?: string
    geographyName?: string
    level?: number
    severity?: string
    isGoodNews?: boolean
    majorUpdateAt?: string
    createdAt?: string
  }
}

interface IncidentsUpdateIncidentTagsData {
  tags: {
    id: number
    display_name: string
  }[]
  manual: boolean
}

export interface IncidentsUpdateIncidentNewsFeedPinData {
  bucket: string
}

interface IncidentsUpdateIncidentThumbnailData {
  horizontalThumbnail?: string
  verticalThumbnail?: string
  y?: number
}

interface IncidentsUploadIncidentContentFileData {
  fileObj: string | Blob
}

interface IncidentsUploadIncidentContentFileFromUrlData {
  url: string
}

interface IncidentsQueryIncidentsData {
  serviceAreas: string[]
  since: string
  until: string
  text: string
  psnId: string
  limit: number
}

interface IncidentReview {
  id: string
  title?: string | null
  text?: string
  level?: number
  location?: string | null
  address?: string | null
  neighborhood?: string | null
  occurredAt: Date
  psnId?: string
  clearanceLevel?: number
  incidentId?: string
  source?: string
  status?: string
  lat?: number
  lng?: number
}

const Incidents = {
  createIncidentContentImage: (
    incidentId: string,
    data: IncidentsCreateIncidentContentImageData,
    options: any = {},
  ) => {
    return OptimusAPI.post(
      `/v1/incidents/${incidentId}/content/images`,
      data,
      options,
    )
  },

  createIncidentModules: (
    incidentId: string,
    data: IncidentsCreateIncidentModulesData,
    options?: any,
  ) => {
    return OptimusAPI.post(
      `/v1/incident/${incidentId}/module_info`,
      data,
      options,
    )
  },

  createIncidentWebView: (
    incidentId: string,
    data: IncidentsCreateIncidentWebViewData,
    options: any = {},
  ) => {
    return OptimusAPI.post(
      `/v1/incidents/${incidentId}/content/web_views`,
      data,
      options,
    )
  },

  deleteIncidentNewsFeedPins: (incidentId: string, bucket: string, options: any = {}) => {
    return OptimusAPI.delete(`/v1/incident/${incidentId}/news_feed_pins/${bucket}`, options)
  },

  deleteIncidentCitywide: (incidentId: string, options: any = {}) => {
    return OptimusAPI.delete(`/v1/incidents/${incidentId}/citywide`, options)
  },

  deleteIncidentContentImage: (
    incidentId: string,
    imageId: string,
    options: any = {},
  ) => {
    return OptimusAPI.delete(
      `/v1/incidents/${incidentId}/content/images/${imageId}`,
      options,
    )
  },

  deleteIncidentContentWebView: (
    incidentId: string,
    webViewId: string,
    options: any = {},
  ) => {
    return OptimusAPI.delete(
      `/v1/incidents/${incidentId}/content/web_views/${webViewId}`,
      options,
    )
  },

  getIncidents: (params: IncidentsGetIncidentsParams, options: any = {}) => {
    // Note: While we try not to do data formatting in our request handlers,
    // Optimus expects incident IDs as a comma separated string, which makes for
    // a less than nice argument API here. Rather than make our interface
    // inconsistent, we chose to format some input data here.
    const { incidentIds, ...restParams } = params

    const formattedParams = {
      incidentIds: incidentIds.join(","),
      ...restParams,
    }

    return OptimusAPI.get(`/incidents2/batch`, {
      ...options,
      params: {
        ...options.params,
        ...formattedParams,
      },
    })
  },

  getIncidents_legacy: (incidentIds: string[], options: any = {}) => {
    // Note: While we try not to do data formatting in our request handlers,
    // this endpoint uses `incident_id` and not the more common `incidentIds` in
    // Optimus. Additionally, Optimus expects incident IDs as a comma separated
    // string, which makes for a less than nice argument API here. Rather than
    // make our interface inconsistent, we chose to format some input data here.
    const formattedParams = {
      incident_ids: incidentIds.join(","),
      with_stats: true,
      with_facepile: true,
    }

    return OptimusAPI.get(`/v1/incidents/batch`, {
      ...options,
      params: {
        ...options.params,
        ...formattedParams,
      },
    })
  },

  getIncidentChatHistory: (
    params: IncidentsGetIncidentChatHistoryParams,
    options: any = {},
  ) => {
    // Note: While we try not to do data formatting in our request handlers,
    // this endpoint uses `incident_id` and not the more common `incidentId` in
    // Optimus. Rather than make our interface inconsistent, we chose to format
    // some input data here.
    const {
      incidentId: incident_id, // Rename to incident_id.
      ...restParams
    } = params

    const formattedParams = { incident_id, ...restParams }

    return OptimusAPI.get(`/v2/incident_chat/history`, {
      ...options,
      params: {
        ...options.params,
        ...formattedParams,
      },
    })
  },

  getIncidentContent: (incidentId: string, rwdb: string, options: any = {}) => {
    return OptimusAPI.get(`/v1/incidents/${incidentId}/content`, {
      ...options,
      params: {
        ...options.params,
        rwdb,
      },
    })
  },

  getIncidentContentImages: (incidentId: string, options?: any) => {
    return OptimusAPI.get(`/v1/incidents/${incidentId}/content/images`, options)
  },

  getIncidentContentWebViews: (incidentId: string, options?: any) => {
    return OptimusAPI.get(
      `/v1/incidents/${incidentId}/content/web_views`,
      options,
    )
  },

  getIncidentHelicopterFlightPath: (incidentId: string, options?: any) => {
    return OptimusAPI.get(`/v1/incident/${incidentId}/historicalTrack`, options)
  },

  getIncidentInventory: (incidentId: string, options: any = {}) => {
    return OptimusAPI.get(
      `/v1/incident/${incidentId}/notification_inventory`,
      options,
    )
  },

  getIncidentNotifications: (incidentId: string, options: any = {}) => {
    return OptimusAPI.get(`/signal/incident_notifications`, {
      ...options,
      params: {
        ...options.params,
        incident_id: incidentId,
      },
    })
  },

  getIncidentPinnedItems: (incidentId: string, options: any = {}) => {
    return OptimusAPI.get(`/v1/incident/${incidentId}/pinned_updates`, {
      ...options,
    })
  },

  getIncidentStreams: (incidentId: string, rwdb: string, options: any = {}) => {
    return OptimusAPI.get(`/v2/incidents/${incidentId}/video_streams`, {
      ...options,
      params: {
        ...options.params,
        rwdb,
      },
    })
  },

  getIncidentTags: (incidentId: string, options?: any) => {
    return OptimusAPI.get(`/signal/incidents/${incidentId}/tags`, options)
  },

  getIncidentNewsFeedPins: (incidentId: string, options?: any) => {
    return OptimusAPI.get(`/v1/incident/${incidentId}/news_feed_pins`, options)
  },

  updateIncident: (
    incidentId: string,
    data: Incident2General,
    options?: any,
  ) => {
    return OptimusAPI.patch(`/incidents2/${incidentId}`, data, options)
  },

  updateIncidentChatHistory: (
    data: IncidentsUpdateIncidentChatHistoryData,
    options?: any,
  ) => {
    return OptimusAPI.post(`/v1/incident_chat`, data, options)
  },

  updateIncidentClosedStatus: (
    incidentId: string,
    data: IncidentsUpdateIncidentCloseStatusData,
    options?: any,
  ) => {
    return OptimusAPI.patch(`/incidents2/${incidentId}`, data, options)
  },

  updateIncidentContentRank: (
    incidentId: string,
    data: IncidentsUpdateIncidentContentRankData,
    options: any = {},
  ) => {
    return OptimusAPI.post(
      `/v1/incidents/${incidentId}/content/set_rank`,
      data,
      options,
    )
  },

  updateIncidentDisplayStyle: (
    incidentId: string,
    data: IncidentsUpdateIncidentDisplayStyleData,
    options?: any,
  ) => {
    return OptimusAPI.post(
      `/v1/incident/${incidentId}/display_style`,
      data,
      options,
    )
  },

  updateIncidentMagicIncident: (
    incidentId: string,
    data: IncidentsUpdateIncidentMagicIncidentData,
    options: any = {},
  ) => {
    return OptimusAPI.patch(`/v1/incidents/${incidentId}/magic`, data, options)
  },

  updateIncidentMapVisibility: (
    incidentId: string,
    data: IncidentsUpdateIncidentMapVisibilityData,
    options?: any,
  ) => {
    return OptimusAPI.post(
      `/v1/incident/${incidentId}/setting`,
      {
        ...data,
        settingName: "map_visibility",
      },
      options,
    )
  },

  updateIncidentNib: (
    incidentId: string,
    data: IncidentsUpdateIncidentNibData,
    options: any = {},
  ) => {
    return OptimusAPI.post(`/v1/incidents/${incidentId}/nibs`, data, options)
  },

  updateIncidentRevision: (
    data: IncidentsUpdateIncidentRevisionData,
    options?: any,
  ) => {
    return OptimusAPI.post(`/incidents2/create_rev`, data, options)
  },

  updateIncidentTags: (
    incidentId: string,
    data: IncidentsUpdateIncidentTagsData,
    options?: any,
  ) => {
    return OptimusAPI.post(
      `/signal/incidents/${incidentId}/update_tags`,
      data,
      options,
    )
  },

  updateIncidentNewsFeedPin: (incidentId: string, data: IncidentsUpdateIncidentNewsFeedPinData, options?: any) => {
    return OptimusAPI.post(`/v1/incident/${incidentId}/news_feed_pin`, data, options)
  },

  updateIncidentThumbnail: (
    incidentId: string,
    data: IncidentsUpdateIncidentThumbnailData,
    options?: any,
  ) => {
    // Note: While we try not to do data formatting in our request handlers,
    // this is the legacy implementation of the formatting for this payload. At
    // the time this refactor was written, it is unclear what the original
    // intent for this formatting was, so we are leaving it as is for now. If
    // you work with this feature in the future please feel free to adjust.
    const { verticalThumbnail, horizontalThumbnail, y } = data
    const formattedData: Incident2General = {}

    if (verticalThumbnail) {
      formattedData.verticalThumbnail = verticalThumbnail
      formattedData.featuredStreamImage = verticalThumbnail
    }

    if (horizontalThumbnail) {
      formattedData.horizontalThumbnail = horizontalThumbnail
      formattedData.featuredStreamImageCropY = y
    }

    return OptimusAPI.patch(`/incidents2/${incidentId}`, formattedData, options)
  },

  uploadIncidentContentFile: (
    incidentId: string,
    data: IncidentsUploadIncidentContentFileData,
    options: any = {},
  ) => {
    const formattedData = new FormData()
    formattedData.append("file", data.fileObj)

    return OptimusAPI.post(
      `/v1/incidents/${incidentId}/content/upload`,
      formattedData,
      options,
    )
  },

  uploadIncidentContentFileFromUrl: (
    incidentId: string,
    data: IncidentsUploadIncidentContentFileFromUrlData,
    options: any = {},
  ) => {
    return OptimusAPI.post(`/v1/incidents/${incidentId}/content/upload`, data, {
      ...options,
      params: {
        ...options.params,
        useUrl: true,
      },
    })
  },

  queryIncidents: (
    data: IncidentsQueryIncidentsData,
    rwdb: string,
    options: any = {},
  ) => {
    return OptimusAPI.post(`/incidents2/query`, data, {
      ...options,
      params: {
        ...options.params,
        rwdb,
      },
    })
  },

  blockIncidentChat: (incidentId: string, isBlocked: boolean, options?: any) => {
    return OptimusAPI.patch(
      `/incidents2/${incidentId}`,
      { chatBlocked: isBlocked },
      options,
    )
  },

  getIncidentReviews: (): Promise<AxiosResponse<IncidentReview[]>> => {
    return OptimusAPI.get("/incident_reviews")
  },

  reviewIncident: (review_id: string, status: string) => {
    return OptimusAPI.post("/incident_reviews/" + review_id, { status })
  },

  getIncidentUpdateReviews: (): Promise<AxiosResponse<IncidentReview[]>> => {
    return OptimusAPI.get("/incident_update_reviews")
  },

  reviewIncidentUpdate: (review_id: string, status: string) => {
    return OptimusAPI.post("/incident_update_reviews/" + review_id, { status })
  },

  pinIncidentUpdate: (incidentId: string, updateId: string, options?: any) => {
    return OptimusAPI.put(
      `/v1/incident/${incidentId}/updates/${updateId}/pin`,
      options,
    )
  },

  unpinIncidentUpdate: (
    incidentId: string,
    updateId: string,
    options?: any,
  ) => {
    return OptimusAPI.put(
      `/v1/incident/${incidentId}/updates/${updateId}/unpin`,
      options,
    )
  },

  rejectNotifExpansion: (incidentId: string, notifExpansion?: NotifExpansionSuggestion, options?: any) => {
    const params = new URLSearchParams({
      type: notifExpansion?.type || '',
      expansion_id: notifExpansion?.expansionID || '',
      auto_expansion_id: notifExpansion?.autoExpansionID || '',
    }).toString()
    return OptimusAPI.put(
      `/incidents2/expansion_suggestions/${incidentId}/reject?${params}`,
      options,
    )
  },

  pauseNotifExpansion: (incidentId: string, notifExpansion?: NotifExpansionSuggestion, options?: any) => {
    const params = new URLSearchParams({
      type: notifExpansion?.type || '',
      expansion_id: notifExpansion?.expansionID || '',
      auto_expansion_id: notifExpansion?.autoExpansionID || '',
    }).toString()
    return OptimusAPI.put(
      `/incidents2/expansion_suggestions/${incidentId}/pause?${params}`,
      options,
    )
  }
}

export default Incidents
