import { chunk, flatten, get } from "lodash"

import { Incidents } from "@guardian/API/Optimus"
import type { IncidentsUpdateIncidentNewsFeedPinData } from "@guardian/API/Optimus/resources/Incidents"
import type { Location } from "@guardian/Types/Common"
import type {EnrichedIncident2, Incident2General, NotifExpansionSuggestion} from "@guardian/Types/Incident"
import type { IncidentReview } from "@guardian/Components/IncidentReview/type"

interface IncidentServiceCreateIncidentContentImageData {
  imageUrl: string
  thumbUrl: string
  isReporter?: boolean
}

interface IncidentServiceCreateIncidentModulesData {
  modules: {
    type: string
    url: string | undefined
    title: string
    isHero: boolean | undefined
    rank: number
    template: {
      name: string
      gcsUrl: string
    }
    id: string
  }[]
}

interface IncidentServiceCreateIncidentWebViewData {
  webViewUrl: string
  thumbUrl: string
}

interface IncidentServiceGetIncidentsParams {
  incidentIds: string[]
  getUpdates?: boolean
  withNibs?: boolean
  withNotifs?: boolean
  withStats?: boolean
}

interface IncidentServiceGetIncidentChatHistoryParams {
  incidentId: string
  limit: number
  before: string
}

interface IncidentServiceUpdateIncidentChatHistoryData {
  incidentId: string
  message: string
}

interface IncidentServiceUpdateIncidentCloseStatusData {
  closed: boolean
}

interface IncidentServiceUpdateIncidentContentRankData {
  contentType: string
  contentId: string
  rank: number
}

interface IncidentServiceUpdateIncidentDisplayStyleData {
  displayStyle: string
}

interface IncidentServiceUpdateIncidentMagicIncidentData {
  magicMomentsTag: string
}

interface IncidentServiceUpdateIncidentMapVisibilityData {
  mapVisibility: string
}

interface IncidentServiceUpdateIncidentNibData {
  id: string
  text: string
  autoExpand: boolean
}

interface IncidentServiceUpdateIncidentRadioDeskActionsData {
  attributes: { [key: string]: boolean }
}

interface IncidentServiceUpdateIncidentRevisionData {
  id: string
  incidentId?: string
  updateId?: string
  updateSequence?: number
  title?: string
  text?: string
  level?: number
  hazard_level?: number
  confidence_level?: number
  newsLevel?: number
  location?: Location
  occurredAt?: Date
  source?: string
  tagIds?: number[]
  tags?: {
    id: number
    name: string
    display_name: string
    type: string
    user_id?: string
    sensitive?: boolean
  }[]
  clipIds?: string[]
  iglStreamId?: string
  prompt911?: boolean
  content?: {
    contentType: string
    contentId: string
  }
  psnId?: string
  clearanceLevel?: number
  dontNotify?: boolean
  excludeFromPath?: boolean
  manualCategorySet?: boolean
  citywide?: {
    incidentID?: string
    geographyType?: string
    geographyCode?: string
    geographyName?: string
    level?: number
    severity?: string
    isGoodNews?: boolean
    majorUpdateAt?: string
    createdAt?: string
  }
}

interface IncidentServiceUpdateIncidentTagsData {
  tags: {
    id: number
    display_name: string
  }[]
  manual: boolean
}

interface IncidentServiceUpdateIncidentThumbnailData {
  horizontalThumbnail?: string
  verticalThumbnail?: string
  y?: number
}

interface IncidentServiceUploadIncidentContentFileData {
  fileObj: string | Blob
}

interface IncidentServiceUploadIncidentContentFileFromUrlData {
  url: string
}

interface IncidentServiceQueryIncidentsData {
  serviceAreas: string[]
  since: string
  until: string
  text: string
  psnId: string
  limit: number
}

const IncidentService = {
  createIncidentContentImage: (
    incidentId: string,
    data: IncidentServiceCreateIncidentContentImageData,
    options: any = {},
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.createIncidentContentImage(incidentId, data, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  createIncidentModules: (
    incidentId: string,
    data: IncidentServiceCreateIncidentModulesData,
    options?: any,
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.createIncidentModules(incidentId, data, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  createIncidentWebView: (
    incidentId: string,
    data: IncidentServiceCreateIncidentWebViewData,
    options: any = {},
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.createIncidentWebView(incidentId, data, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  deleteIncidentNewsFeedPins: (incidentId: string, bucket: string, options: any = {}) => {
    return new Promise((resolve, reject) => {
      Incidents.deleteIncidentNewsFeedPins(incidentId, bucket, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  deleteIncidentCitywide: (incidentId: string, options: any = {}) => {
    return new Promise((resolve, reject) => {
      Incidents.deleteIncidentCitywide(incidentId, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  deleteIncidentContentImage: (
    incidentId: string,
    imageId: string,
    options: any = {},
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.deleteIncidentContentImage(incidentId, imageId, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  deleteIncidentContentWebView: (
    incidentId: string,
    webViewId: string,
    options: any = {},
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.deleteIncidentContentWebView(incidentId, webViewId, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  getIncident: (incidentId: string, options: any = {}) => {
    return new Promise((resolve, reject) => {
      Incidents.getIncidents_legacy([incidentId], options)
        .then(resp => {
          // Note: Legacy implmentation used lodash (`get`) here. Unsure this is
          // necessary. If you use this code, please consider reviewing and
          // updating. We should work to minimize lodash and hope to arrive at
          // a point where we've rid it from our codebase.
          const incident = get(resp, "data[0]", null)

          resolve(incident)
        })
        .catch(reject)
    })
  },

  getIncidents: (
    params: IncidentServiceGetIncidentsParams,
    options: any = {},
  ): Promise<Array<EnrichedIncident2>> | Array<EnrichedIncident2> => {
    const incidentIds = IncidentService.getValidIncidentIds(params.incidentIds)

    if (incidentIds.length === 0) {
      return [] as EnrichedIncident2[]
    }

    return new Promise((resolve, reject) => {
      // Note: Legacy implmentation used lodash (`chunk`) here. Unsure this is
      // necessary. If you use this code, please consider reviewing and
      // updating. We should work to minimize lodash and hope to arrive at a
      // point where we've rid it from our codebase.
      const incidentIdBatches = chunk(incidentIds, 100)

      Promise.all(
        incidentIdBatches.map(incidentIds => {
          return IncidentService._internal_getIncidents(
            { ...params, incidentIds },
            options,
          )
        }),
      )
        .then(batchedIncidents => {
          // Note: Legacy implmentation used lodash (`flatten`) here. Unsure
          // this is necessary. If you use this code, please consider reviewing
          // and updating. We should work to minimize lodash and hope to arrive
          // at a point where we've rid it from our codebase.
          const incidents = (flatten(batchedIncidents) || []) as Array<
            EnrichedIncident2
          >

          resolve(incidents)
        })
        .catch(reject)
    })
  },

  getIncidentChatHistory: (
    params: IncidentServiceGetIncidentChatHistoryParams,
    options: any = {},
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.getIncidentChatHistory(params, options)
        .then(resp => {
          // Note: Legacy implmentation used lodash (`get`) here. Unsure this is
          // necessary. If you use this code, please consider reviewing and
          // updating. We should work to minimize lodash and hope to arrive at
          // a point where we've rid it from our codebase.
          const chatHistory = get(resp, "data.messages", [])

          resolve(chatHistory)
        })
        .catch(reject)
    })
  },

  getIncidentContent: (incidentId: string, rwdb: string, options: any = {}) => {
    return new Promise((resolve, reject) => {
      Incidents.getIncidentContent(incidentId, rwdb, options)
        .then(({ data: incidentContent }) => {
          resolve(incidentContent)
        })
        .catch(reject)
    })
  },

  getIncidentContentImages: (incidentId: string, options?: any) => {
    return new Promise((resolve, reject) => {
      Incidents.getIncidentContentImages(incidentId, options)
        .then(({ data: responseImages }) => {
          const images = responseImages || []

          resolve(images)
        })
        .catch(reject)
    })
  },

  getIncidentContentWebViews: (incidentId: string, options?: any) => {
    return new Promise((resolve, reject) => {
      Incidents.getIncidentContentWebViews(incidentId, options)
        .then(({ data: responseWebViews }) => {
          const webViews = responseWebViews || []

          resolve(webViews)
        })
        .catch(reject)
    })
  },

  getIncidentHelicopterFlightPath: (incidentId: string, options?: any) => {
    return new Promise((resolve, reject) => {
      Incidents.getIncidentHelicopterFlightPath(incidentId, options)
        .then(({ data: helicopterFlightPath }) => {
          resolve(helicopterFlightPath)
        })
        .catch(reject)
    })
  },

  getIncidentInventory: (incidentId: string, options: any = {}) => {
    return new Promise((resolve, reject) => {
      Incidents.getIncidentInventory(incidentId, options)
        .then(({ data: incidentInventory }) => {
          resolve(incidentInventory)
        })
        .catch(reject)
    })
  },

  getIncidentNotifications: (incidentId: string, options: any = {}) => {
    return new Promise((resolve, reject) => {
      Incidents.getIncidentNotifications(incidentId, options)
        .then(({ data: { notifications } }) => {
          resolve(notifications)
        })
        .catch(reject)
    })
  },

  getIncidentPinnedItems: (incidentId: string, options: any = {}) => {
    return new Promise((resolve, reject) => {
      Incidents.getIncidentPinnedItems(incidentId, options)
        .then(({ data: pinnedItems }) => {
          resolve(pinnedItems)
        })
        .catch(reject)
    })
  },

  getIncidentStreams: (incidentId: string, rwdb: string, options: any = {}) => {
    return new Promise((resolve, reject) => {
      Incidents.getIncidentStreams(incidentId, rwdb, options)
        .then(({ data: streams }) => {
          resolve(streams)
        })
        .catch(reject)
    })
  },

  getIncidentTags: (incidentId: string, options?: any) => {
    return new Promise((resolve, reject) => {
      Incidents.getIncidentTags(incidentId, options)
        .then(({ data: { results: tags } }) => {
          resolve(tags)
        })
        .catch(reject)
    })
  },

  getIncidentNewsFeedPins: (incidentId: string, options?: any) => {
    return new Promise((resolve, reject) => {
      Incidents.getIncidentNewsFeedPins(incidentId, options)
        .then(({ data: { buckets } }) => {
          resolve(buckets)
        })
        .catch(reject)
    })
  },

  updateIncident: (
    incidentId: string,
    data: Incident2General,
    options?: any,
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.updateIncident(incidentId, data, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  updateIncidentChatHistory: (
    data: IncidentServiceUpdateIncidentChatHistoryData,
    options?: any,
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.updateIncidentChatHistory(data, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  updateIncidentClosedStatus: (
    incidentId: string,
    data: IncidentServiceUpdateIncidentCloseStatusData,
    options?: any,
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.updateIncidentClosedStatus(incidentId, data, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  updateIncidentContentRank: (
    incidentId: string,
    data: IncidentServiceUpdateIncidentContentRankData,
    options: any = {},
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.updateIncidentContentRank(incidentId, data, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  updateIncidentDisplayStyle: (
    incidentId: string,
    data: IncidentServiceUpdateIncidentDisplayStyleData,
    options?: any,
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.updateIncidentDisplayStyle(incidentId, data, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  updateIncidentMagicIncident: (
    incidentId: string,
    data: IncidentServiceUpdateIncidentMagicIncidentData,
    options: any = {},
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.updateIncidentMagicIncident(incidentId, data, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  updateIncidentMapVisibility: (
    incidentId: string,
    data: IncidentServiceUpdateIncidentMapVisibilityData,
    options?: any,
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.updateIncidentMapVisibility(incidentId, data, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  updateIncidentNib: (
    incidentId: string,
    data: IncidentServiceUpdateIncidentNibData,
    options: any = {},
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.updateIncidentNib(incidentId, data, options)
        .then(resp => {
          // Note: Legacy implmentation used lodash (`get`) here. Unsure this is
          // necessary. If you use this code, please consider reviewing and
          // updating. We should work to minimize lodash and hope to arrive at
          // a point where we've rid it from our codebase.
          const nib = get(resp, "data.results", [])

          resolve(nib)
        })
        .catch(reject)
    })
  },

  updateIncidentRevision: (
    data: IncidentServiceUpdateIncidentRevisionData,
    options?: any,
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      Incidents.updateIncidentRevision(data, options)
        .then(({ data: { incident } }) => {
          resolve(incident)
        })
        .catch(reject)
    })
  },

  updateIncidentTags: (
    incidentId: string,
    data: IncidentServiceUpdateIncidentTagsData,
    options?: any,
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.updateIncidentTags(incidentId, data, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  updateIncidentNewsFeedPin: (incidentId: string, data: IncidentsUpdateIncidentNewsFeedPinData, options?: any) => {
    return new Promise((resolve, reject) => {
      Incidents.updateIncidentNewsFeedPin(incidentId, data, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  updateIncidentThumbnail: (
    incidentId: string,
    data: IncidentServiceUpdateIncidentThumbnailData,
    options?: any,
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.updateIncidentThumbnail(incidentId, data, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  uploadIncidentContentFile: (
    incidentId: string,
    data: IncidentServiceUploadIncidentContentFileData,
    options: any = {},
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.uploadIncidentContentFile(incidentId, data, options)
        .then(resp => {
          // Note: Legacy implmentation used lodash (`get`) here. Unsure this is
          // necessary. If you use this code, please consider reviewing and
          // updating. We should work to minimize lodash and hope to arrive at
          // a point where we've rid it from our codebase.
          const url = get(resp, "data.url")

          resolve(url)
        })
        .catch(reject)
    })
  },

  uploadIncidentContentFileFromUrl: (
    incidentId: string,
    data: IncidentServiceUploadIncidentContentFileFromUrlData,
    options: any = {},
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.uploadIncidentContentFileFromUrl(incidentId, data, options)
        .then(resp => {
          // Note: Legacy implmentation used lodash (`get`) here. Unsure this is
          // necessary. If you use this code, please consider reviewing and
          // updating. We should work to minimize lodash and hope to arrive at
          // a point where we've rid it from our codebase.
          const url = get(resp, "data.url")

          resolve(url)
        })
        .catch(reject)
    })
  },

  queryIncidents: (
    data: IncidentServiceQueryIncidentsData,
    rwdb: string,
    options: any = {},
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.queryIncidents(data, rwdb, options)
        .then(({ data: { incidentIds } }) => {
          resolve(incidentIds)
        })
        .catch(reject)
    })
  },

  blockIncidentChat: (incidentId: string, isBlocked: boolean, options?: any) => {
    return new Promise((resolve, reject) => {
      Incidents.blockIncidentChat(incidentId, isBlocked, options)
        .then(() => {
          resolve(isBlocked)
        })
        .catch(reject)
    })
  },

  pinIncidentUpdate: (incidentId: string, updateId: string, options?: any) => {
    return new Promise((resolve, reject) => {
      Incidents.pinIncidentUpdate(incidentId, updateId, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  unpinIncidentUpdate: (
    incidentId: string,
    updateId: string,
    options?: any,
  ) => {
    return new Promise((resolve, reject) => {
      Incidents.unpinIncidentUpdate(incidentId, updateId, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  // Note: At the end of the day, our Incidents.getIncidents request handler is
  // using an external endpoint that expects to handle batched requests for
  // incidents. However, in the effort towards separating the logic of batching
  // results from making these requests, we decided to separate the work of
  // actually performing the request call to this separate
  // `_internal_getIncidents` method, as apart from the `getIncidents` method,
  // which is expected to be called by users of this service.
  _internal_getIncidents: (
    params: IncidentServiceGetIncidentsParams,
    options: any = {},
  ): Promise<Array<EnrichedIncident2>> | Array<EnrichedIncident2> => {
    const incidentIds = IncidentService.getValidIncidentIds(params.incidentIds)

    if (incidentIds.length === 0) {
      return [] as EnrichedIncident2[]
    }

    return new Promise((resolve, reject) => {
      interface Params {
        incidentIds: string[]
        updateLimit: number
        withNibs?: string
        withNotifs?: string
        withStats?: string
      }

      const formattedParams: Params = {
        incidentIds: incidentIds,
        updateLimit: params.getUpdates ? 100 : 10,
      }

      if (params.withNibs) {
        formattedParams.withNibs = "true"
      }

      if (params.withNotifs) {
        formattedParams.withNotifs = "true"
      }

      if (params.withStats) {
        formattedParams.withStats = "true"
      }

      Incidents.getIncidents(formattedParams, options)
        .then(({ data: { incidents } }) => {
          resolve(incidents)
        })
        .catch(reject)
    })
  },

  isValidIncidentId: (incidentId: string) => {
    return incidentId && incidentId.startsWith("-") && incidentId.length === 20
  },

  getValidIncidentId: (incidentId: string) => {
    return IncidentService.isValidIncidentId(incidentId) ? incidentId : null
  },

  getValidIncidentIds: (incidentIds: string[]) => {
    return incidentIds.filter(incidentId =>
      IncidentService.isValidIncidentId(incidentId),
    )
  },

  getIncidentReviews: (): Promise<IncidentReview[]> => {
    return new Promise((resolve, reject) => {
      Incidents.getIncidentReviews()
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },

  reviewIncident: (incidentId: string, status: string) => {
    return new Promise((resolve, reject) => {
      Incidents.reviewIncident(incidentId, status)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  getIncidentUpdateReviews: (): Promise<IncidentReview[]> => {
    return new Promise((resolve, reject) => {
      Incidents.getIncidentUpdateReviews()
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },

  reviewIncidentUpdate: (incidentId: string, status: string) => {
    return new Promise((resolve, reject) => {
      Incidents.reviewIncidentUpdate(incidentId, status)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  rejectNotifExpansion: (incidentId: string, notifExpansion?: NotifExpansionSuggestion, options?: any) => {
    return new Promise((resolve, reject) => {
      Incidents.rejectNotifExpansion(incidentId, notifExpansion, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  pauseNotifExpansion: (incidentId: string, notifExpansion?: NotifExpansionSuggestion, options?: any) => {
    return new Promise((resolve, reject) => {
      Incidents.pauseNotifExpansion(incidentId, notifExpansion, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  }
}

export default IncidentService
