import _sortBy from "lodash/sortBy"
import _get from "lodash/get"

import { hasKeyword } from "../util"
import { getClipRawMetadata, getClipTranscriptionText } from "./metadata"
import DuplicateDetection from "./duplicateDetection"

export const isClipFlagged = (clip, algorithm, clipPriorityConfig) => {
  if (isClipFlaggedByConfig(clip, algorithm)) {
    return true
  }
  if (isUpdateClip(clip)) {
    return true
  }
  if (isMedicalIncidentClip(clip, clipPriorityConfig)) {
    return true
  }
  return false
}

export const isClipFlaggedByConfig = (c, algorithm) => {
  switch (algorithm) {
    case "legacy":
      return isClipFlaggedByLegacy(c)
    case "v3_gpt":
    default:
      return isClipFlaggedByV3Gpt(c)
  }
}

const isClipFlaggedByLegacy = c => {
  return !!(c.flags || c.metadata?.tags)
}

const isClipFlaggedByV3Gpt = clip => {
  if (!clip?.metadata?.raw) {
    return false
  }
  const rawMetadata = getClipRawMetadata(clip)
  const filtering = rawMetadata?.filtering
  if (filtering) {
    return !!filtering.is_positive
  }
}

export const isMedicalIncidentClip = (clip, clipPriorityConfig) => {
  const {
    medicalIncidentsFilteringChannels,
    medicalIncidentsFilteringKeywords,
  } = clipPriorityConfig || {}

  if (
    !medicalIncidentsFilteringChannels?.length ||
    !medicalIncidentsFilteringKeywords?.length ||
    !medicalIncidentsFilteringChannels.includes(clip?.channel)
  ) {
    return false
  }

  const transcriptionText = getClipTranscriptionText(clip)
  return hasKeyword(transcriptionText, medicalIncidentsFilteringKeywords)
}

export const isUpdateClip = clip => {
  const rawMetadata = getClipRawMetadata(clip)
  const updateDetection = rawMetadata?.update_detection || {}
  if (typeof updateDetection !== "object") {
    return false
  }
  return Object.keys(updateDetection).length > 0
}

export const isAutoGenClip = clip => {
  const rawMetadata = getClipRawMetadata(clip)
  return !!rawMetadata?.is_autogen_incident
}

export const getIncidentSuggestions = clip => {
  const rawMetadata = getClipRawMetadata(clip)
  const updateDetection = rawMetadata?.update_detection || {}

  if (
    typeof updateDetection !== "object" ||
    Object.keys(updateDetection).length === 0
  ) {
    return []
  }

  // Return the incident suggestions in descending order of score (i.e. most likely first)
  return Object.entries(updateDetection)
    .map(([incidentId, data]) => ({
      incidentId,
      ...data,
    }))
    .sort((a, b) => b.score - a.score)
}

const isDuplicateClip = (state, clip) => {
  const rawMetadata = getClipRawMetadata(clip)
  const updateDetection = rawMetadata?.duplicate_detection || {}
  if (updateDetection?.is_duplicate) {
    state.get().radio.duplicateClips.add(clip.id)
  }
  return state.get().radio.duplicateClips.has(clip.id)
}

export const filterClips = (state, clips) => {
  const radioState = state.get().radio
  let {
    mutes,
    cutoff,
    flaggedOnly,
    multiplayerPinOnly,
    allowedChannels,
    channels: channelCfg,
    flaggedConfig,
  } = radioState

  // This will detect all duplicates and populate to the duplicateClips set
  try {
    DuplicateDetection.detectAllDuplicates(state, clips)
  } catch (e) {
    console.error(e)
  }
  const now = Date.now()

  return clips.filter(c => {
    if (mutes[c.channel]) {
      return false
    }
    if (c.duration_ms < cutoff * 1000) {
      return false
    }
    if (multiplayerPinOnly && !c.multiplayerPinTime) {
      return false
    }

    const channel = channelCfg[c.channel]
    const { lag, flaggingSupport } = readFlaggedConfig(
      flaggedConfig,
      channel.serviceArea,
      channel.subArea,
    )
    if (lag && new Date(now - lag * 1000) <= new Date(c.time)) {
      return false
    }

    // Filter out duplicate clips
    if (isDuplicateClip(state, c)) {
      return false
    }

    if (flaggedOnly) {
      if (isAutoGenClip(c)) {
        // Auto-gen clips are hidden by default when flaggedOnly is enabled
        return false
      }
      if (isClipFlagged(c, state)) {
        return true
      }
      if (!flaggingSupport || !channel.starred) {
        return true
      }
      if (!allowedChannels.has(c.channel)) {
        return false
      }
    }
    return true
  })
}

const sortByClipTimeMostRecentFirst = (c, timeSelector = null) => {
  const timestamp = timeSelector ? timeSelector(c) : c.time
  const time = new Date(timestamp).getTime()
  return -time
}

const sortByFilteringScore = clip => {
  const rawMetadata = getClipRawMetadata(clip)
  return -rawMetadata?.filtering?.prob_positive ?? 0
}

const SORTING_MAP = {
  confidence: sortByFilteringScore,
  timestamp: sortByClipTimeMostRecentFirst,
}

export const sortClips = (clips, sortMethod, timeSelector = null) => {
  const sortFn = SORTING_MAP[sortMethod] ?? SORTING_MAP.timestamp
  const defaultSortFns = [c => sortFn(c, timeSelector), c => c.id]
  return _sortBy(clips, defaultSortFns)
}

export const getUnplayedClipsCount = (clips, activeClip) => {
  if (!activeClip.time) {
    return 0
  }

  return clips.filter(
    c =>
      !c.played &&
      new Date(activeClip.time).getTime() > new Date(c.time).getTime(),
  ).length
}

export const readFlaggedConfig = (flaggedConfig, serviceArea, subArea) => {
  const serviceAreaConfig = flaggedConfig[serviceArea] || {}
  const overrides = _get(serviceAreaConfig || {}, "overrides", {})
  const subAreaConfig = _get(overrides, subArea, {})

  let { lag, ttl, flaggingSupport, algorithm } = flaggedConfig.DEFAULT ?? {}
  if (serviceAreaConfig?.hasOwnProperty("flaggingSupport")) {
    lag = serviceAreaConfig.lag || lag
    ttl = serviceAreaConfig.ttl || ttl
    flaggingSupport = serviceAreaConfig.flaggingSupport
    algorithm = serviceAreaConfig.algorithm
  }
  if (subAreaConfig?.hasOwnProperty("flaggingSupport")) {
    lag = subAreaConfig.lag || lag
    ttl = subAreaConfig.ttl || ttl
    flaggingSupport = subAreaConfig.flaggingSupport
    algorithm = subAreaConfig.algorithm
  }

  return {
    lag,
    ttl,
    flaggingSupport,
    algorithm,
  }
}

export const selectFlaggingAlgorithm = (channel, state) => {
  const { flaggedConfig, flagAlgorithm } = state.radio
  return getFlaggingAlgorithm(channel, flaggedConfig, flagAlgorithm)
}

export const getFlaggingAlgorithm = (channel, flaggedConfig, flagAlgorithm) => {
  if (!channel) {
    return null
  }

  const clipFlaggedConfig = readFlaggedConfig(flaggedConfig, channel.serviceArea, channel.subArea)
  return flagAlgorithm || clipFlaggedConfig?.algorithm?.name
}
